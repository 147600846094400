import React from "react";
import { CalendarIcon } from "../svgs/icons";
import dayjs from "dayjs";
import { colors } from "../../../styles/tagColors";
const ReactMarkdown = require("react-markdown");
const gfm = require('remark-gfm')

const Root_URL = window.Root_URL;

export default function LatestNewsBox({ currentPost, lang = "en" }) {
  const {
    featuredImage = "",
    urlParam = "",
    title = "",
    tags = [],
    author = {},
    date = "",
    content = "",
  } = currentPost;
  return (
    <div className="card">
{/**
      <div className="card-bar-top">
      </div>
      <img
        className="card-img-top"
        src={featuredImage && featuredImage}
        alt={`${urlParam && urlParam}`}
      />
*/}      
      <div className="card-body wideCard">
        <h4 className="card-title">{title && title}</h4>
        {tags &&
          tags.map(({ name, value }, i) => {
            return (
              <a href="#" className="tag" key={`hm_tgs-${i}`}>
                <span
                  className="badge"
                  style={{
                    backgroundColor: `${colors[value - 1] || colors[2]}`,
                    color: "#fff",
                  }}
                >
                  {name}
                </span>
              </a>
            );
          })}
        <div className="avatar-box">
          <div>
            <span className="avatar">
              <img
                src={`${Root_URL}img/${author && author.image}`}
                alt={`${author && author.name}`}
              />
            </span>
            <span className="avatar-title">{author && author.name}</span>
          </div>
          <div cent-flex="true" col-muted={"true"}>
            <CalendarIcon className="avatar-text-icon" />
            {console.log(date)}
            <span>
              {date &&
                dayjs(date)
                  .locale(lang)
                  .format("DD MMMM, YYYY")}
            </span>
          </div>
        </div>
        <ReactMarkdown remarkPlugins={[gfm]} children={content && content} className={"markdown"} />
      </div>
    </div>
  );
}
