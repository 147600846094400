import axios from "axios";
const rootUrl = window.Root_URL;
// console.log(rootUrl);
const getBlogPost = (slug, country, lang) => {
  return axios
    .get(`${rootUrl}blog/${country}/${lang}/${slug}.json`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
const getBlogMap = (country, lang) => {

  if (country == "DE_BL" || country == "DE_TSO") {
	  country = "DE";
  }
	
  return axios
    .get(`${rootUrl}blog/${country}/${lang}/blogMap.json`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export { getBlogPost, getBlogMap };
