import React from 'react';
import Coverflow from 'react-coverflow';
import { StyleRoot } from 'radium';
import { useTranslation } from "../../hooks/customHooks";

const CoverFlowSlider = () => {
  const { t } = useTranslation();
  const { lang, country } = JSON.parse(sessionStorage.getItem("locale"));

  return (
    <StyleRoot>
      {(country=="DE" || country=="DE_BL" || country=="DE_TSO") &&
      <Coverflow
        id="CoverFlowCarousel"
        infiniteScroll={true}
        displayQuantityOfSide={3}
        navigation={false} /* important, true causes FFox Bug (no 3d perspective on covers) */
        enableHeading={true}
        enableScroll={false} /* do not use - page scroll down interferes with carousell horizontal scroll*/
        active={0}      
        clickable={true}
        currentFigureScale={0.8}
        otherFigureScale={0.6}
        media={{
          '@media (max-width: 499px)': {
            width: 'auto',
            height: 'auto',
          },          
          '@media (min-width: 500px)': {
            width: 'auto',
            height: 'auto',
            maxHeight: '300px',
          }
        }}
      >

      {/* TODO: FIX sessionstorage problem, loads only initial country, causes links not loaded correctly mb. test with 2 separate coverflow react apps */}
      
      
      {/* IMPORTANT: check correct order of slides! */}
      {/* prev PICs: second half (n/2 +1..n) showing left hand */}
      <img src='./img/carousel/61_scenarios_2.svg' alt={t(`mainMenu.remod`) + ": " + t(`mainMenu.remod-application-technologies`)} data-action="https://www.energy-charts.info/charts/remod_sector_data/chart.htm?c=DE"/>
      <img src='./img/carousel/60_scenarios_1.svg' alt={t(`mainMenu.remod`) + ": " + t(`mainMenu.remod-installed-power`)} data-action="https://www.energy-charts.info/charts/remod_installed_power/chart.htm?c=DE"/>
      <img src='./img/carousel/50_renewable_share_map.svg' alt={t(`mainMenu.ren-share-map`)} data-action="https://www.energy-charts.info/charts/renewable_share_map/chart.htm?c=DE"/>
      <img src='./img/carousel/42_emissions.svg' alt={t(`mainMenu.emissions`)} data-action="https://www.energy-charts.info/charts/emissions/chart.htm?c=DE&year=-1"/>
      <img src='./img/carousel/41_climatestripes.svg' alt={t(`air_color_flat`)} data-action="https://www.energy-charts.info/charts/climate_annual_average/chart.htm?c=DE&source=air_color_flat"/>
      <img src='./img/carousel/40_airtemp.svg' alt={t(`mainMenu.annual-avg`)} data-action="https://www.energy-charts.info/charts/climate_annual_average/chart.htm?c=DE"/>
      <img src='./img/carousel/35_price-eeg.svg' alt={t(`mainMenu.eeg-account`)} data-action="https://www.energy-charts.info/charts/eeg_account/chart.htm?c=DE"/>
      <img src='./img/carousel/34_price-value.svg' alt={t(`mainMenu.market-values`)} data-action="https://www.energy-charts.info/charts/market_values/chart.htm?c=DE"/>
      {/*<img src='./img/carousel/32_price-volume.svg' alt={t(`mainMenu.price-volume`)} data-action="https://www.energy-charts.info/charts/price_volume/chart.htm?c=DE"/>*/}
      <img src='./img/carousel/30_price.svg' alt={t(`mainMenu.spot-prices`)} data-action="https://www.energy-charts.info/charts/price_spot_market/chart.htm?c=DE"/>

      {/* MIDDLE (0) - THIS IS ACTIVE on load*/}
      <img src='./img/carousel/00_power.svg'alt={t(`mainMenu.electricity-prod`)} data-action="https://www.energy-charts.info/charts/power/chart.htm?c=DE"/>

      {/* next PICs: first half (1..n/2 -1) showing right hand */}
      
      <img src='./img/carousel/11_power_sim.svg' alt={t(`mainMenu.electricity-prod-simulated`)} data-action="https://www.energy-charts.info/charts/power_simulated/chart.htm?c=DE"/>
      
      <img src='./img/carousel/12_power_inst.svg' alt={t(`mainMenu.installed-power`)} data-action="https://www.energy-charts.info/charts/installed_power/chart.htm?c=DE"/>
      <img src='./img/carousel/13_power_heatmap.svg' alt={t(`mainMenu.power-heatmaps`)} data-action="https://www.energy-charts.info/charts/power_heatmaps/chart.htm?c=DE"/>      
      <img src='./img/carousel/20_energy.svg' alt={t(`mainMenu.bar-chart`)} data-action="https://www.energy-charts.info/charts/energy/chart.htm?c=DE"/>
      <img src='./img/carousel/21_energy_pie.svg' alt={t(`mainMenu.pie-chart`)} data-action="https://www.energy-charts.info/charts/energy_pie/chart.htm?c=DE"/>
      <img src='./img/carousel/22_energy_ren-share.svg' alt={t(`mainMenu.renewable`)} data-action="https://www.energy-charts.info/charts/renewable_share/chart.htm?c=DE"/>
      <img src='./img/carousel/24_energy_import-export.svg' alt={t(`mainMenu.import-export`)} data-action="https://www.energy-charts.info/charts/import_export/chart.htm?c=DE"/>
      <img src='./img/carousel/28_energy_redispatch.svg' alt={t(`mainMenu.energy_redispatch`)} data-action="https://www.energy-charts.info/charts/energy_redispatch/chart.htm?c=DE"/>

      </Coverflow>
      }

      {/* spacer for swiss coverflow 
      country=="CH" &&
      */}      
    </StyleRoot>
  )
}
export default CoverFlowSlider