import React from "react";
import { Html5Entities } from "html-entities";
const htmlEntities = new Html5Entities();

function logoOnclick() {
  window.open('https://www.tnc.ch/');
}

export default function TncLogoBig({ className , lang}) {

  const rootUrl = window.Root_URL;
  return (
    <div className={className ? className : undefined}>
      <img src={`${rootUrl}img/logo_TNC_claim.png`} title="TNC - Advanced Energy Concepts" onClick={logoOnclick} style={{"width": "300px"}}/>
    </div>
  );
}
