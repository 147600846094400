import React from 'react';
import Coverflow from 'react-coverflow';
import { StyleRoot } from 'radium';
import { useTranslation } from "../../hooks/customHooks";

const CoverFlowSlider_CH = () => {
  const { t } = useTranslation();
  const { lang, country } = JSON.parse(sessionStorage.getItem("locale"));

  return (
    <StyleRoot>
      {country=="CH" &&
      <Coverflow
        id="CoverFlowCarousel"
        infiniteScroll={true}
        displayQuantityOfSide={3}
        navigation={false} /* important, true causes FFox Bug (no 3d perspective on covers) */
        enableHeading={true}
        enableScroll={false} /* do not use - page scroll down interferes with carousell horizontal scroll*/
        active={0}      
        clickable={true}
        currentFigureScale={0.8}
        otherFigureScale={0.6}
        media={{
          '@media (max-width: 499px)': {
            width: 'auto',
            height: 'auto',
          },          
          '@media (min-width: 500px)': {
            width: 'auto',
            height: 'auto',
            maxHeight: '300px',
          }
        }}
      >

      {/* IMPORTANT: check correct order of slides! */}
      {/* prev PICs: second half (n/2 +1..n) showing left hand */}
      <img src='./img/carousel_CH/50_renewable_share_map.svg' alt={t(`mainMenu.ren-share-map`)} data-action="https://www.energy-charts.info/charts/renewable_share_map/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/43_hydro.svg' alt={t(`mainMenu.hydrologie`)} data-action="https://www.energy-charts.info/charts/hydrology/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/41_climatestripes.svg' alt={t(`air_color_flat`)} data-action="https://www.energy-charts.info/charts/climate_annual_average/chart.htm?c=CH&source=air_color_flat"/>
      <img src='./img/carousel_CH/40_airtemp.svg' alt={t(`mainMenu.annual-avg`)} data-action="https://www.energy-charts.info/charts/climate_annual_average/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/33_price-average.svg' alt={t(`mainMenu.price-average`)} data-action="https://www.energy-charts.info/charts/price_average/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/30_price.svg' alt={t(`mainMenu.spot-prices`)} data-action="https://www.energy-charts.info/charts/price_spot_market/chart.htm?c=CH"/>

      {/* MIDDLE (0) - THIS IS ACTIVE on load*/}
      <img src='./img/carousel_CH/00_power.svg'alt={t(`mainMenu.electricity-prod`)} data-action="https://www.energy-charts.info/charts/power/chart.htm?c=CH"/>

      {/* next PICs: first half (1..n/2 -1) showing right hand */}
      <img src='./img/carousel_CH/12_power_inst.svg' alt={t(`mainMenu.installed-power`)} data-action="https://www.energy-charts.info/charts/installed_power/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/13_power_heatmap.svg' alt={t(`mainMenu.power-heatmaps`)} data-action="https://www.energy-charts.info/charts/power_heatmaps/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/20_energy.svg' alt={t(`mainMenu.bar-chart`)} data-action="https://www.energy-charts.info/charts/energy/chart.htm?c=CH"/>
      {/*<img src='./img/carousel_CH/21_energy_pie.svg' alt={t(`mainMenu.pie-chart`)} data-action="https://www.energy-charts.info/charts/energy_pie/chart.htm?c=CH"/>*/}
      <img src='./img/carousel_CH/22_energy_ren-share.svg' alt={t(`mainMenu.renewable`)} data-action="https://www.energy-charts.info/charts/renewable_share/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/23_energy_storage.svg' alt={t(`mainMenu.storage`)} data-action="https://www.energy-charts.info/charts/filling_level/chart.htm?c=CH"/>
      <img src='./img/carousel_CH/24_energy_import-export.svg' alt={t(`mainMenu.import-export`)} data-action="https://www.energy-charts.info/charts/import_export/chart.htm?c=CH"/>

      </Coverflow>
      }

      {/* spacer for swiss coverflow 
      country=="CH" &&
      */}      
    </StyleRoot>
  )
}
export default CoverFlowSlider_CH