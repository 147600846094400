import React from "react";

export default function NotFound({ title = "", message = null, image = "" }) {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">{title}</h4>
        {message}
      </div>
    </div>
  );
}
