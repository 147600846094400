import React, { useEffect, useState} from "react";

import CoverFlowSlider from "./hmpgComps/carousel";
import CoverFlowSlider_CH from "./hmpgComps/carousel_CH";

import HmpgWelcome from "./hmpgComps/hmpgWelcome";
import HmpgInstructions from "./hmpgComps/hmpgInstructions";
import HmpgSponsors from "./hmpgComps/hmpgSponsors";
import LatestNewsBox from "./hmpgComps/latestNewsBox";
import NewsCarousel from "./hmpgComps/newsCarousel";

import SocialBox from "./social/socialBox";
import NotFound from "./functionalComps/notFound";
import Skeleton from "./functionalComps/skeleton";
import { useTranslation } from "../hooks/customHooks";
import { getBlogMap } from "../../utils/service";

require("dayjs/locale/en");
require("dayjs/locale/de");


export default function HomePage() {
  const [locale] = useState(sessionStorage.getItem("locale"));
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState({ value: true, message: "empty" });
  const { t } = useTranslation();
  const { lang, country } = JSON.parse(locale);
  const rootUrl = window.Root_URL;
  
  useEffect(() => {
	  
    (async function fetchBlogMap() {
      await getBlogMap(country, lang)
        .then(({ posts }) => {
          setLoading(false);
          // console.log(posts.length);
          if (posts.length != 0) setIsEmpty({ value: false, message: "empty" });
          const featuredPosts = posts.filter(
            (post) => post.featuredPost === true
          );
          let tempBlog = featuredPosts.sort((a, b) => a.date > b.date);
          setBlogPosts(tempBlog.reverse());
          // console.log(blogPosts);
        })
        .catch((err) => {
          setLoading(false);
          setIsEmpty({ value: true, message: "error" });
        });
    })();
  }, []);
  const currentPost = blogPosts[0] || {};
  const posts = blogPosts.slice(1, 3);

  return (
    <>

      {/** Carousel/Slider atm. slides for DE only (add CH / other Countries later) [for ch add '|| country=="CH"' */}
      {(country=="DE" || country=="DE_BL" || country=="DE_TSO") &&
        <CoverFlowSlider />
      } 
      {(country==="CH") &&
        <CoverFlowSlider_CH />
      }

      {/* DE/EU only*/}
      { country != "CH" && 
        <section top-pad="true">
          <div className="sect_container">
            <div className="row">
              <div className="col-md-8 card-bot-margin-til-768">
                {/** Welcome Message and Website information*/}
                <HmpgWelcome />
              </div>
              <div className="col-md-4">
                  <SocialBox lang={lang} country={country} scope="ecTalksCard"/>
              </div>
            </div>
          </div>
        </section>
      }

      {/* CH only*/}
      { country === "CH" && 
        <section top-pad="true">
          <div className="sect_container ">
            <div className="row">
              <div className="col-md-8 card-bot-margin-til-768">
                {/** Welcome Message and Website information*/}
                <HmpgWelcome />
              </div>
              <div className="col-md-4">
                {/** Socialbox section for CH */}
                  <SocialBox lang={lang} country={country} scope="ecTalksCard"/>
              </div>  
            </div>
          </div>
        </section>
      }

      {/** News/Blog Section */}
      {country !== "CH" &&
      <section top-pad="true" bot-pad="true">
        <div className="sect_container ">
          {/*<h3>{t("recent-news")}</h3>*/}
          <div className="row">
              {loading ? (
                <Skeleton amt={8} />
              ) : (
                <div className="col-md-8 ">
                  {/* {console.log(isEmpty.value)} */}
                  {isEmpty.value ? (
                    <NotFound title={t(`blog.${isEmpty.message}`)} />
                  ) : (
                    <>
                      <LatestNewsBox currentPost={currentPost} lang={lang} />
                      <NewsCarousel
                        posts={posts}
                        country={country}
                        lang={lang}
                        loading={loading}
                      />
                    </>
                  )}
                </div>
              )}

            {/** NewsSideBox Section  */}
            <div className="col-md-4" style={{ minHeight: "500px" }}>
              <div className="sticky">
                <SocialBox lang={lang} country={country} scope="followCard_moreLinksBoxCard"/>
              </div>
              {/*}
              <div style={{ visibility: "hidden" }}>
                Sticky parent container requires another element. So this has a
                visibility of hidden
              </div>
                  */}
            </div>  
          </div>
        </div>
      </section>
      }      

      {country === "CH" &&
      <section top-pad="true" bot-pad="true">
        <div className="sect_container ">
          {/*<h3>{t("recent-news")}</h3>*/}
          <div className="row">
              {loading ? (
                <Skeleton amt={8} />
              ) : (
                <div className="col-md-8 ">
                  {/** Sponsor Section  */}
                  <HmpgSponsors />
                </div>
              )}

            {/** NewsSideBox Section  */}
            <div className="col-md-4">
              <div className="sticky">
                <SocialBox lang={lang} country={country} scope="followCard_moreLinksBoxCard"/>
              </div>
              {/*}
              <div style={{ visibility: "hidden" }}>
                Sticky parent container requires another element. So this has a
                visibility of hidden
              </div>
                  */}
            </div>  
          </div>
        </div>
      </section>
      }    

    </>
  );
}
