import React from "react";
import { useTranslation } from "../../hooks/customHooks";
import ECLogo from "../svgs/ecLogo";
import FHISELogo from "../svgs/fhISELogo";
import SwissCross from "../svgs/swissCross";
import SwissEcLogo from "../svgs/swissEcLogo"
import CLToggleComps from "../CLToggleComps";
import { ActiveCountryFlag } from "../countryToggle";

export default function HmpgWelcome() {
  const { t } = useTranslation();
  const { lang, country } = JSON.parse(sessionStorage.getItem("locale"));
  return (
    <div className="card card-fullHeight" bot-pad="true">
      <div className="welcomeBox">
        {/** Welcome DE */}
        {country!="CH" && 
          <>
          <div className="logoInWelcomeBox">
            <div>
              <ECLogo className="brandLogoWelcome" logoDashclass={"logoDash"} /> 
            </div>
            <div className="flagIconAtLogoWelcome">
              <ActiveCountryFlag />
            </div>
          </div>
          <p><b>{t(`home-content.welcome.general.sub-headline`)}</b></p>
          <br />
          <p>{t(`home-content.welcome.general.content-1`)}</p>
          <br />
          <p>{t(`home-content.instructions.section-2.general.content-1`)}{" "}
            <a type="link" target="_blank" rel="noopener noreferrer" href={t(`home-content.instructions.section-2.ise-url`)}>
              {t(`home-content.instructions.section-2.ise`)}{" "}
            </a>
          {t(`home-content.instructions.section-2.general.content-2`)}</p>
          </>
        }
        {/** Welcome CH */}
        {country=="CH" &&
          <>
          <div>
            <span className={"SwissCrossContainer"} style={{"marginRight": "1em"}}><SwissCross className="brandLogoSwissWelcome"/></span>
            <SwissEcLogo className="brandLogoSwissWelcome"/>
          </div>
          <p><b>{t(`home-content.welcome.CH.sub-headline_neu`)}</b></p>
          <br />
          <p>{t(`home-content.welcome.CH.content-1_neu`)}</p>          
          {/*
          <h1>{t(`home-content.welcome.CH.headline`)}</h1>
          <p> {t(`home-content.welcome.CH.sub-headline`)}</p>
          <p> {t(`home-content.welcome.CH.content-1`)}</p>
          */}
        </>
        }
      </div>

    </div>
  );
}
