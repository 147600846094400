import React from "react";
import { CalendarIcon } from "../svgs/icons";
import dayjs from "dayjs";
import { colors } from "../../../styles/tagColors";
import Skeleton from "../functionalComps/skeleton";
import Shiitake from "shiitake";
const LineClamp = Shiitake;
const Root_URL = window.Root_URL;
export default function NewsCarousel({
  posts,
  country,
  lang,
  loading = false,
}) {
  return (
    <div className="row" top-pad="true">
      {loading ? (
        <Skeleton amt={4} />
      ) : (
        <>
          {posts.map(
            (
              {
                title,
                featuredImage,
                excerpt,
                urlParam,
                tags,
                author,
                date,
                content,
              },
              i
            ) => {
              return (
                <div
                  className="col-md-6"
                  key={`blg_pst-${i}`}
                  bot-margin="true"
                >
                  <div className="card blogTile">
                    <div className="card-body mdCard">
                      <a
                        href={`${Root_URL}post.html?l=${lang}&c=${country}&q=${urlParam}`}
                      >
                        <LineClamp
                          lines={2}
                          throttleRate={200}
                          className="card-title"
                          tagName="h4"
                        >
                          {title}
                        </LineClamp>
                      </a>
                      {tags?.map(({ name, value }, i) => {
                        return (
                          <a href="#" className="tag" key={`blg_tg-${i}`}>
                            <span
                              className="badge"
                              style={{
                                backgroundColor: `${colors[value - 1] ||
                                  colors[2]}`,
                                color: "#fff",
                              }}
                            >
                              {name}
                            </span>
                          </a>
                        );
                      })}
                      <div className="avatar-box">
                        <div>
                          <span className="avatar">
                            <img
                              src={`${Root_URL}img/${author?.image}`}
                              alt={`${author?.name}`}
                            />
                          </span>
                          <span className="avatar-title">{author?.name}</span>
                        </div>
                        <div cent-flex="true" col-muted={"true"}>
                          <CalendarIcon className="avatar-text-icon" />
                          <span>
                            {date &&
                              dayjs(date)
                                .locale(lang)
                                .format("DD MMMM, YYYY")}
                          </span>
                        </div>
                      </div>
                      <LineClamp lines={2} throttleRate={200} tagName="p">
                        {excerpt}
                      </LineClamp>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
    </div>
  );
}
