import React from "react";

export default function Skeleton({ amt = 1 }) {
  return (
    <div className="skeleton skeleton-active">
      <div className="skeleton-content">
        <ul className="skeleton-paragraph">
          {[...Array(Number(amt))].map((_, i) => {
            return <li key={`sk${i}`}></li>;
          })}
        </ul>
      </div>
    </div>
  );
}
