import React from "react";
import { useTranslation } from "../../hooks/customHooks";
import TncLogoBig from "../misc/TNCLogoBig";
import EnergySchweizLogo from "../misc/energieSchweizLogo";

export default function HmpgSponsors() {
  const { t } = useTranslation();
  const { lang, country } = JSON.parse(sessionStorage.getItem("locale"));

  return (
    <>
    {country =="CH" &&
      <div className="card" bot-pad="true" bg-white="true">
          <div className="sponsorBox">
              <div className="sponsor-logo-row">
                <TncLogoBig className="sponsor-logo-column" />
              </div>
              <div className="sponsor-logo-row">
                <EnergySchweizLogo className="sponsor-logo-column" lang={lang}/>
            </div>
          </div>
      </div>
    }
    </>
  );
}
