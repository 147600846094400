import React, { useState } from "react";
import { useTranslation } from "../../hooks/customHooks";
import getNextECTalksDate from "../../data/nextTalksDates.json";

{/*}
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Skeleton from "../functionalComps/skeleton";
*/}
const Root_URL = window.Root_URL;
const nextTalks = getNextECTalksDate;

function getTwitterName(lang, country) {
  /** DE/ALL acccount dep. on lang, none any more for CH */
  if (country != "CH") {
    switch(lang) {
      case "de":
        return "energy_charts_d";
      case "fr":
        return "energy_charts_f";
      case "en":
      default:
        return "energy_charts";
    }
  }
}

function getBlueskyData(lang, country) {
  /** atm only 1 DE/ALL acccount*/
  if (country != "CH") {
    let _blueskyData = {
      link:"https://bsky.app/profile/energy-charts.bsky.social", 
      accountname:"@energy-charts.bsky.social"
    };
    return (_blueskyData);
  }
}

function getLinkedInData(lang, country) {
  /** 1x DE/ALL & 1x CH*/
  if (country == "CH") {
    let _linkedInData = {
      link:"https://www.linkedin.com/company/swiss-energy-charts/", 
      accountname:"swiss-energy-charts"
    };
    return (_linkedInData);
  } else {
    let _linkedInData = {
      link:"https://www.linkedin.com/in/bruno-burger-a8144021/recent-activity/all/", 
      accountname:"Bruno Burger"
    };
    return (_linkedInData);
  }
}

function getMastodonData(lang, country) {
  /** atm only 1 DE/ALL acccount*/
  if (country != "CH") {
    let _mastodonData = {
      link:"https://wisskomm.social/@energy_charts_d", 
      accountname:"@energy_charts_d"
    };
    return (_mastodonData);
  }
}

function getThreadsData(lang, country) {
  /** atm only one acccount, add more later*/
  if (country != "CH") {
    let _ThreadsData = {
      link:"https://www.threads.net/@energy_charts", 
      accountname:"@energy_charts"
    };
    return (_ThreadsData);
  }
}

export default function SocialBox({lang, country, scope}) {
  const twitter_name = getTwitterName(lang, country);
  const bluesky_data = getBlueskyData(lang, country);
  const linkedIn_data = getLinkedInData(lang, country);
  const mastodon_data = getMastodonData(lang, country);
  const threads_data = getThreadsData(lang, country);
  const [loadPlugin, setLoadPlugin] = useState(false);
  const { t } = useTranslation();
  const confirm = (state) => {
    setLoadPlugin(state);
  };
  const twitterText = () => (
    <>
      <p>{t("twitter-policy-1")}</p>
      <p>{t("twitter-policy-2")}</p>
    </>
  );
  const mastodonText = () => (
    <>
      <p>{t("mastodon-policy-1")}</p>
      <p>{t("mastodon-policy-2")}</p>
    </>
  );
  
  //check scope - if not defined set default
  /* available scopes are:
  * allCards: show all
  * moreLinksBoxCard: show only newsBox
  * followCard: show follow buttons only
  * ecTalksCard:show nextEC TAlks Dates only
  * 
  * combinations:
  * twitterCards: show only TwitterTimeLine and TwitterFollow
  * ecTalks_follow_cards
  * 
  * single cards:
  * twitterTimeLineCard: show only TwitterTimeLine
  * twitterFollowCard: show only twitterFollow
  * blueskyFollowCard
  * linkedInFollowCard
  * mastodonFollowCard
  */

  if(scope == undefined){
    scope = "allCards"
  } 

  return (
    <>
    {/*EC Talks Info Box*/}
      { (scope=='allCards' || scope == 'ecTalksCard_followCard' || scope == 'ecTalksCard') && 
      <div className="card card-fullHeight" bot-margin="true">
        {/*TODO: check/add top margin depending on SCOPE*/}
        <div className="linkBox">
          <p className="rm"><b>{t('ecTalks-text_1')}</b></p>

          {/*ADD/DELETE List of available Talks Dates here. 
          There should be set a maximum of 3 future dates, defined in .\jsx\data\nextTalksDates File */}
          <p className="rm">{nextTalks.talks_41[lang]}</p>
          <p className="rm">{nextTalks.talks_42[lang]}</p>
                    
          <p className="rm">&nbsp;</p>
          <p className="rm">{t('ecTalks-text_2')}</p>
          <a
            className="customisable-highlight" target="_blank" rel="noopener" 
            href={"https://s.fhg.de/energy-charts-talks" }
          >
            {"https://s.fhg.de/energy-charts-talks"}
          </a>
          <a
            className="customisable-highlight" 
            href={"https://www.energy-charts.info/energy-charts-talks.html"}
          >
            {t('ecTalks-records')}
          </a>
        </div>
      </div>
      }

    {/*Follow Buttons*/}  
    { (scope=='allCards' || scope == 'ecTalksCard_followCard' || scope == 'followCard_moreLinksBoxCard' || scope=='followCard') &&
      <div className="card" bot-margin="true">
        {/*Spread notation if there is the need of css attributes with condition
        <div className="card" {...(country == "" ? {"top-margin":"true"} : {})}></div>*/}
        
        <div className="linkBox">
          <p className="rm"><b>{t("follow-us")}</b></p>
          <p className="rm"><br /></p>

          {/* Twitter / X */}
          {/* show button not in CH*/}
          { country != "CH" &&
            <>
            <p className="rm">X</p>
            <a
              className="btn btn-primary btn-twitter"  target="_blank" rel="noopener"
              href={"https://twitter.com/"+twitter_name}
            >
              @{twitter_name}
            </a>
            </>
          }

          {/* Bluesky 
          official logo discussion: https://github.com/FortAwesome/Font-Awesome/issues/19810
          */}
          {/* show button not in CH*/}
          { country != "CH" &&
            <>
            <p className="rm">Bluesky</p>
            <a className="btn bluesky"  target="_blank" rel="noopener" href={bluesky_data.link}>
              {bluesky_data.accountname}
            </a>
            </>
          }

          {/* LinkedIn */}
          {/* show button for ALL*/}
            <p className="rm">LinkedIn</p>
            <a
              className="btn linkedIn"  target="_blank" rel="noopener" href={linkedIn_data.link}>
              {linkedIn_data.accountname}
            </a>

          {/* Mastodon */}
          {/* show button not in CH*/}
          { country != "CH" &&
            <>
            <p className="rm">Mastodon</p>
            <a
              className="btn mstdn" target="_blank" rel="noopener" href={mastodon_data.link}>
              {mastodon_data.accountname}
            </a>
            </>
          }

          {/* Threads / Facebook */}
          { country != "CH" &&
            <>         
            <p className="rm">Threads</p>
            <a
              className="btn threads" target="_blank" rel="noopener"
              href={threads_data.link}
            >
              {threads_data.accountname}
            </a>
            </>
          }

        </div>
      </div>
    }
    
    {/* More Links BOX*/}
    { country != "CH" &&
      <>       
      {(scope=='allCards' || scope == 'followCard_moreLinksBoxCard' || scope == 'moreLinksBoxCard') &&
      <div className="card">
        {/*TODO: check/add top margin depending on SCOPE*/} 
        <div className="linkBox">

          <div className="imageTopText">
          <a href={`${Root_URL}faqs.html`}>
            <img src="./img/fhg_background_s.webp" alt="FAQ" width="100%"></img>
            <div>{t("visit-tutorial")}</div>
          </a>
          </div>
          <div className="imageTopText">
            <a href={`${Root_URL}downloads.html`}>
              <img src="./img/download_banner_s.webp" alt="Downloads" width="100%"></img>
              <div>{t("visit-app-downloads")}</div>
            </a>
          </div>
          <div className="imageTopText">
            <a href={`${Root_URL}blog.html`}>
              <img src="./img/blog_banner_s.webp" alt="Blog" width="100%"></img>
              <div>{t("visit-blog")}</div>
            </a>
          </div>

        </div>
      </div>
      }
      </>
    }
    { country == "CH" &&
      <>       
      {(scope=='allCards' || scope == 'followCard_moreLinksBoxCard' || scope == 'moreLinksBoxCard') &&
      <div className="card">
        {/*TODO: check/add top margin depending on SCOPE*/} 
        <div className="linkBox">
          <div className="imageTopText">
            <a href={`${Root_URL}faqs.html`}>
              <img src="./img/fhg_background_s.webp" alt="FAQ" width="100%"></img>
              <div>{t("visit-tutorial")}</div>
            </a>
            </div>
        </div>
      </div>
      }
      </>
    }    

{/*Mastodon TimeLine*/}
{/**
    {(scope=='allCards' || scope == 'mastodonCards' ) && country != "CH" && 
      <div className="card" style={{ padding: "1em" }} top-margin="true">
        {loadPlugin ? (
          <>
            <div className="mt-timeline" bot-pad="true">
              <div id="mt-body" className="mt-body" role="feed">
                <div className="loading-spinner"></div>
              </div>
            </div>
            <button
              className="btn btn-primary mstdn"
              type="button"
              onClick={() => confirm(false)}
                          >
              {t("mastodon-collapse-toots")}
            </button>
          </>
        ) : (
          <div>
            <div className="twTimelineHeader">
              <h3
                className="timeline-Header-title u-inlineBlock"
                data-scribe="element:title"
              >
                {t("mastodon-toots")}
                <span
                  className="timeline-Header-byline"
                  data-scribe="element:byline"
                >
                  {t("mastodon-by")}
                  <a
                    className="customisable-highlight"
                    href={"https://wisskomm.social/@"+mastodon_name}
                    title={"&lrm;@"+mastodon_name+" on Mastodon"}
                  >
                    &lrm;@{mastodon_name}
                  </a>
                </span>
              </h3>
            </div>
            <div className="twitterCover">
              {<Skeleton amt={5} />}
              <div className="acceptTerms">
                <button
                  type="button"
                  className="btn btn-primary mstdn"
                  data-toggle="modal"
                  data-target="#twitterModal"
                >
                  {t("mastodon-load-toots")}
                </button>
                <div
                  className="modal fade"
                  id="twitterModal"
                  tabIndex="-1"
                  style={{ zIndex: "3" }}
                  role="dialog"
                  aria-labelledby="twitterModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">{mastodonText()}</div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          {t("no_button")}
                        </button>
                        <button
                          onClick={confirm}
                          type="button"
                          className="btn btn-primary btn-twitter"
                          data-dismiss="modal"
                        >
                          {t("yes_button")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div top-pad="true">
          <a className="wisskomTerms" href="https://wisskomm.social/privacy-policy">
            {t("mastodon-terms")}
          </a>
        </div>
      </div>
    }
**/}

      {/*Twitter TimeLine*/}
{/** DEACTIVATE TWITTER TIMELINE, July 2023     
      
    {(scope=='allCards' || scope == 'twitterCards' || scope=='twitterTimeLineCard') &&
      <div className="card" style={{ padding: "1em" }} top-margin="true">
        {loadPlugin ? (
          <>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={twitter_name}
              options={{ tweetLimit: 3 }}
              placeholder={<Skeleton amt={3} />}
            />
            <button
              className="btn btn-primary btn-twitter"
              type="button"
              onClick={() => confirm(false)}
            >
              {t("collapse-tweets")}
            </button>
          </>
        ) : (
          <div>
            <div className="twTimelineHeader">
              <h3
                className="timeline-Header-title u-inlineBlock"
                data-scribe="element:title"
              >
                {`Tweets `}
                <span
                  className="timeline-Header-byline"
                  data-scribe="element:byline"
                >
                  {`by `}
                  <a
                    className="customisable-highlight"
                    href={"https://twitter.com/"+twitter_name}
                    title={"&lrm;@"+twitter_name+" on Twitter"}
                  >
                    &lrm;@{twitter_name}
                  </a>
                </span>
              </h3>
            </div>
            <div className="twitterCover">
              {<Skeleton amt={5} />}
              <div className="acceptTerms">
                <button
                  type="button"
                  className="btn btn-primary btn-twitter"
                  data-toggle="modal"
                  data-target="#twitterModal"
                >
                  {t("load-tweets")}
                </button>
                <div
                  className="modal fade"
                  id="twitterModal"
                  tabIndex="-1"
                  style={{ zIndex: "3" }}
                  role="dialog"
                  aria-labelledby="twitterModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">{dataText()}</div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          {t("no_button")}
                        </button>
                        <button
                          onClick={confirm}
                          type="button"
                          className="btn btn-primary btn-twitter"
                          data-dismiss="modal"
                        >
                          {t("yes_button")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <a className="twitterTerms" href="https://twitter.com/privacy">
          {t("twitter-terms")}
        </a>
      </div>
    }
**/} 


    </>
  );
}
