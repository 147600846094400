import React, { Component } from "react";
import ReactDOM from "react-dom";
import Homepage from "../comps/homepage";

class App extends Component {
  render() {
    return <Homepage />;
  }
}

ReactDOM.render(<App />, document.getElementById("homepage"));
